import React from "react";
import { Container, Section } from "../components";

const Policies = () => {
  return (
    <Section>
      <Container className="data">
        <h1>Property policies</h1>

        <h2>About us</h2>

        <p>
          Amidst the endearing meadows and playful streams, surrounded by the
          majestic peaks of Chandrashila, Nanda Devi and Chowkhamba, and blessed
          by the world's highest Shiva temple - The Shrine of Tungnath, lies the
          quaint valley of Chopta. The valley is crowded not by people, but
          myriad natural wonders like waterfalls, evergreen forests and
          innumerable species of birds and animals.
        </p>

        <p>
          The Bunker House, an unparalleled Café and Stay property located in
          this very ‘Mini Switzerland of Uttarakhand’, empowers its visitors to
          experience the spectacular beauty of Chopta without compromising on
          the comfort.
        </p>

        <p>
          The Multi - Cuisine Café is built on the concept of shared
          experiences, with a common area complete with fun activities and a
          distinguished view, fit for both indoor and outdoor fun. The
          accommodation is focused on ensuring privacy while also giving an
          option to bunk together. The all over décor blends with the earthy
          tones of the surroundings and radiates a homely vibe.
        </p>

        <p>
          With an unrelenting commitment to the environment, we use solar power
          and organize adventurous yet environment friendly activities that take
          your exploration of the beauty of Chopta up a notch. A humble and
          contemporary start up, we stand for quality and integrity in all that
          we do, and strive to make The Bunker House your home, away from home.
        </p>

        <h2>Terms and Conditions</h2>
        <ul>
          <li>
            {" "}
            Only guests with valid ID proof will be allowed entry. Permissible
            ID proofs include - Aadhar Card, PAN card, Voter ID or Driver's
            License for Indians, and Passport/Relevant documentation for
            Foreigners.
          </li>
          <li> No Food services in the room</li>
          <li> The café accepts orders from 9.00 am to 9.00 pm only.</li>
          <li>
            {" "}
            Children above 08 years of age would be considered as adult and an
            extra adult rate would be applicable.
          </li>
          <li>
            {" "}
            Standard terms and conditions (House Rules) of the hostel apply for
            all guests.
          </li>
          <li> Rights of Admission Reserved.</li>
          <li>
            {" "}
            Any avoidable mishaps, accidents or injuries to guests during the
            entire stay and any other activities taken up within the premises
            would not be held responsible towards the management.
          </li>
          <li>
            {" "}
            Guests are responsible for their own belongings and any loss of
            valuables and items would not be the responsibility of the
            management.
          </li>
          <li>
            {" "}
            No provision of toiletries in hostel rooms/shared rooms, Kits will
            be provided upon request and will be charged extra. To be cleared at
            the time of check out.
          </li>
          <li>
            {" "}
            Keeping in view of the property's location within a wildlife
            sanctuary (protected area), guests will be liable to adhere by the
            guidelines prescribed by the government of India, and any fines they
            incur due to inability to abide by them.
          </li>
          <li>
            {" "}
            Guests are requested to conserve electricity on a need to use basis,
            since the we at The Bunker house are a solar power backed stay.
          </li>
        </ul>
        <h2>Payment Terms:</h2>
        <ul>
          <li>
            {" "}
            Payments can be done by cash or online net-banking or UPI payment.
          </li>
          <li> All extras to be cleared at the reception before departure.</li>
          <li>
            {" "}
            Min. Of 50% of the total is necessary as an advance to a booking and
            the rest to be payed at the check-in.
          </li>
        </ul>
        <p> Check In and Check Out</p>
        <ul>
          <li>Check in time is 12.30 pm and check out time is 11 am.</li>
          <li>
            All the rooms will have to be vacated at the day of check out at
            11.00 am. Early/Late check out will be given subject to availability
            at extra cost.
          </li>
        </ul>
        <h2>Privacy Policy</h2>

        <p>
          All information provided by the guests would be kept private unless
          the guest is liable under a legal obligation.
        </p>

        <h2>Cancellation/Refund Policy</h2>
        <ul>
          <li>
            Cancellations made 7 days prior to the booking will be liable to a
            full refund.
          </li>
          <li>
            {" "}
            Cancellations made 3 days prior to the booking will be liable to a
            50% refund.
          </li>
          <li>
            {" "}
            Cancellations made within 3 days prior to the booking will be liable
            to no refund.
          </li>
        </ul>
      </Container>
    </Section>
  );
};

export default Policies;
